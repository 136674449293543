<template>
  <list-template :current-page="page" :loading="loading" :search-config="searchConfig" :table-config="tableConfig" :table-data="tableData.list" :total="total" @onChangePage="changeCurrentPage" @onHandle="tableHandle" @onReset="onReset" @onSearch="onSearch">
  </list-template>
</template>

<script>
import {mapState} from 'vuex'
import ListTemplate from "@/components/pages/List.vue"

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  components: {ListTemplate},
  data() {
    return {
      loading: true,
      dataTable: {
        result: []
      },
      tableConfig: [
        {
          prop: 'name',
          label: '测评名称'
        },
        {
          prop: 'student',
          label: '学生姓名'
        },
        {
          prop: 'student_no',
          label: '学号',
          width: 150
        },
        {
          prop: 'average',
          label: '全量表总分'
        },
        {
          prop: 'result',
          label: '全量表异常指标项'
        },
        {
          prop: 'positive',
          label: '效度量表得分'
        },
        {
          prop: 'class',
          label: '行政班',
          width: 150
        },
        {
          prop: 'grade',
          label: '年级'
        },
        {
          prop: 'school',
          label: '校区'
        },
        {
          prop: 'state_text',
          label: '提交状态',
          render: (row) => {
            return `<div style="display: flex;align-items: center">
                      <div style="width: 8rem;height: 8rem;border-radius: 50%; background: ${row.state_text === '已提交' ? '#36C538' : row.state_text === '未提交' ? '#FFC53E' : ''} "></div>
                      <span>${row.state_text}</span>
                    </div>`
          }
        },
        {
          label: '操作',
          handle: true,
          width: '80',
          buttons: [{ type:"view", text:"查看" }]
        }
      ],
      searchConfig: [
        {
          tag: 'select',
          placeholder: '搜索测评名称',
          prop: 'evaluation_name',
          label: 'name',
          value: 'id',
          reserveKeyword: true,
          filterable: true,
          remote: true,
          remoteMethod: (key) => {
            this.remoteMethod(key)
          },
          options: []
        },
        {
          prop: "student_name",
          placeholder: "搜索学生姓名"
        },
        {
          prop: "average",
          placeholder: "筛选全量表总分分段",
          tag: 'select',
          label: 'name',
          value: 'id',
          options: []
        },
        {
          prop: "result",
          placeholder: "筛选全量表异常指标项",
          tag: 'select',
          label: 'name',
          value: 'id',
          collapseTags: true,
          multiple: true,
          options: []
        },
        {
          prop: "positive",
          placeholder: "筛选效度量表总分分段",
          tag: 'select',
          label: 'name',
          value: 'id',
          options: []
        },
        {
          prop: 'school_id',
          placeholder: '筛选校区',
          tag: 'select',
          label: 'name',
          value: 'id',
          change: (val,search) => {
            if(search.grade_id) {
              search.grade_id = ''
            }
            if(search.class_id) {
              search.class_id = ''
            }
            this.searchConfig[6].options = []
            this.searchConfig[7].options = []
            this.$_axios2.get('api/evaluation/result/get-range?school_id=' + val).then(res=>{
              this.searchConfig[6].options = res.data.data.grade
              this.searchConfig[7].options = res.data.data.class
            })
          },
          options: []
        },
        {
          prop: 'grade_id',
          placeholder: '筛选年级',
          tag: 'select',
          label: 'name',
          value: 'id',
          change: (val,search) => {
            if(search.class_id) {
              search.class_id = ''
            }
            this.searchConfig[7].options = []
            this.$_axios2.get('api/evaluation/result/get-range?grade_id=' + val + '&school_id=' + this.dataTable.school_id).then(res => {
              this.searchConfig[7].options = res.data.data.class
            })
          },
          options: []
        },
        {
          prop: 'class_id',
          placeholder: '筛选行政班',
          tag: 'select',
          label: 'name',
          value: 'id',
          options: []
        },
        {
          prop: 'state',
          placeholder: '筛选提交状态',
          tag: 'select',
          value: 'id',
          label: 'name',
          options: []
        },
      ],
      tableData: {},
      total: 0,
      loadingTo: false,
      evaluation_name: '',
    }
  },
  mounted() {
    this.$store.commit('setPage', 1)
    this.getData()
  },
  activated() {
    this.getData()
  },
  computed: {
    ...mapState(['page'])
  },
  methods: {
    changeCurrentPage(e) {
      this.$store.commit('setPage', e)
      this.getData()
    },

    getData() {
      this.loading = true;
      sessionStorage.getItem('dataTable') !== null ? (this.dataTable = JSON.parse(sessionStorage.getItem('dataTable'))) : this.dataTable
      if (this.dataTable.name) {
        this.evaluation_name = this.dataTable.name
      }
      if (this.dataTable.school_id && sessionStorage.getItem('dataTable') !== null) {
        this.$_axios2.get('api/evaluation/result/get-range?school_id=' + this.dataTable.school_id).then(res => {
          this.searchConfig[6].options = res.data.data.grade
          this.$forceUpdate()
        })
        if (this.dataTable.grade_id && sessionStorage.getItem('dataTable') !== null) {
          this.$_axios2.get('api/evaluation/result/get-range?grade_id=' + this.dataTable.grade_id).then(res => {
            this.searchConfig[7].options = res.data.data.class
            this.$forceUpdate()
          })
        }
      }
      sessionStorage.removeItem('dataTable')
      let data = {...this.dataTable}
      if (data.result) data.result = data.result.join(',')
      this.$_axios2.get('api/evaluation/result/mht', {params: {page: this.page, ...data}}).then(res => {
        if (res.data.data.list) {
          this.tableData = res.data.data
          this.total = res.data.data.page.total
        } else {
          this.tableData = []
          this.total = 0
        }
      }).finally(() => this.loading = false);
    },

    onSearch(val) {
      this.$store.commit('setPage', 1)
      this.dataTable = val
      this.getData()
    },

    onReset() {
      this.$store.commit('setPage', 1)
      this.dataTable = {}
      this.getData()
      this.commonInterface()
      this.searchConfig[6].options = []
      this.searchConfig[7].options = []
      this.evaluation_name = ''
    },

    tableHandle(row) {
      this.$router.push('./examine?id=' + row.id)
    },

    commonInterface() {
      this.$_axios2.get('api/evaluation/result/get-range').then(res => {
        this.searchConfig[5].options = res.data.data.school
        this.$forceUpdate()
      })
    },
    remoteMethod(query) {
      this.loadingTo = true;
      let params = {}
      params.keyword = query
      this.$_axios2.get('/api/evaluation/result/keyword-search', {params}).then(res => {
        this.searchConfig[0].options = res.data.data
      }).finally(() => this.loadingTo = false)

    },

  },
  created() {
    this.commonInterface()
    this.$_axios2.get('api/evaluation/result/mht-search').then(res => {
      this.searchConfig[2].options = res.data.data.average
      this.searchConfig[3].options = res.data.data.result
      this.searchConfig[4].options = res.data.data.positive
      this.searchConfig[8].options = res.data.data.state
      this.$forceUpdate()
    })
  },
}
</script>

<style lang="scss" scoped></style>
